<template>
  <div class="first">
    <br>
    <ATitle text="第四届回顾" icon="6"></ATitle>
    <br>
    <div class="first-info center tc">
      <div class="prv">
        <div class="fl">
          <video poster="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2022/宣传片封面.jpg" controls="controls" src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2022/宣传片.mp4"></video>
        </div>
        <div class="flex info">
          <div class="item"><h1>8000㎡</h1><p>搭建面积</p></div>
          <div class="item"><h1>802万人次</h1><p>线上云展点击人次</p></div>
          <div class="item"><h1>205家</h1><p>国内外参展企业</p></div>
          <div class="item"><h1>47个</h1><p>重点项目签约</p></div>
          <div class="item"><h1>866亿元</h1><p>总投资</p></div>
          <div class="item"><h1>2100万</h1><p>全网曝光量</p></div>
        </div>
      </div>
    </div>
    <CTitle text="开幕式主旨论坛"></CTitle>
    <!-- <div class="f-swiper"><router-link tag="a" to="/forum4?thread=0"><Swiper baseUrl="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2022/开幕式暨主旨论坛/" :list="mainImgList"></Swiper></router-link></div> -->
    <div class="f-swiper"><Swiper baseUrl="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2022/开幕式暨主旨论坛/" :list="mainImgList"></Swiper></div>
    <br>
    <CTitle text="主题论坛"></CTitle>
    <div class="forum-outer">
      <div class="flex forum-wrap center">
        <router-link tag="a" to="/forum4?thread=1" class="forum-item">
          <div class="flex img">
            <img src="//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/116690886286455-%E7%99%BE%E5%BA%A6.jpg?x-oss-process=image/resize,h_340,w_620,m_fill">
          </div>
          <div class="text">“绿色·低碳”创新发展论坛</div>
        </router-link>
        <router-link tag="a" to="/forum4?thread=2" class="forum-item">
          <div class="flex img">
            <img src="//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/116690883225068-%E9%AB%98%E9%80%9F.jpg?x-oss-process=image/resize,h_340,w_620,m_fill">
          </div>
          <div class="text">智慧高速建设与发展论坛</div>
        </router-link>
        <router-link tag="a" to="/forum4?thread=3" class="forum-item">
          <div class="flex img">
            <img src="//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/616693598839238-16%EF%BC%9A9.jpg?x-oss-process=image/resize,h_340,w_620,m_fill">
          </div>
          <div class="text">海运与物流发展论坛</div>
        </router-link>
        <router-link tag="a" to="/forum4?thread=4" class="forum-item">
          <div class="flex img">
            <img src="//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/016693623473601-%E4%B8%BB%E8%A7%86%E8%A7%8916%EF%BC%9A9.jpg?x-oss-process=image/resize,h_340,w_620,m_fill">
          </div>
          <div class="text">“云感交通·数领未来”论坛</div>
        </router-link>
        <router-link tag="a" to="/forum4?thread=5" class="forum-item">
          <div class="flex img">
            <img src="//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/716690895900529-%E8%BD%A8%E9%81%93.jpg?x-oss-process=image/resize,h_340,w_620,m_fill">
          </div>
          <div class="text">轨道交通发展论坛</div>
        </router-link>
        <router-link tag="a" to="/forum4?thread=6" class="forum-item">
          <div class="flex img">
            <img src="//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/116693699221691-%E5%A4%A7%E8%B5%9B%E4%B8%BBK.jpg?x-oss-process=image/resize,h_340,w_620,m_fill">
          </div>
          <div class="text">第三届综合交通创新创业大赛总决赛</div>
        </router-link>
      </div>
    </div>
    <CTitle text="博览会照片"></CTitle>
    <div class="elevator-wrap center">
      <div class="fl elevator" style="height: 162px;">
        <div class="flex item" @click="cur=0" :class="cur==0&&'cur'">序厅</div>
        <div class="flex item" @click="cur=1" :class="cur==1&&'cur'">数字交通馆</div>
      </div>
      <div class="sponsor-list">
        <SwiperNode :cur="cur" :imgList="imgList"></SwiperNode>
      </div>
    </div>
    <div class="annual-elevator">
      <router-link tag="div" class="annual" to="/fifth">第五届</router-link>
      <div class="annual cur">第四届</div>
      <router-link tag="div" class="annual" to="/third">第三届</router-link>
      <router-link tag="div" class="annual" to="/second">第二届</router-link>
      <router-link tag="div" class="annual" to="/first">第一届</router-link>
    </div>
  </div>
</template>
<script>
import Swiper from '@/components/Swiper'
import SwiperNode from '@/components/SwiperNode'
export default {
  components: {
    Swiper,
    SwiperNode
  },
  data() {
    return {
      cur: 0,
      mainImgList: ['7U5A4107.jpg','7U5A4142.jpg','7U5A4176.jpg','IMG_0101.jpg','IMG_0386.jpg'],
      imgList: [
        {
          name: '序厅',
          desc: '',
          baseUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2022/数字交通馆/',
          iconUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/展馆logo/exhibition-area8.png',
          img: [['7U5A3954.jpg','7U5A3993.jpg','7U5A3995.jpg','7U5A3996.jpg','IMGM9820.jpg','IMGM9823.jpg','YJE_6675.jpg','YJE_6676.jpg','YJE_6682.jpg']]
        },
        {
          name: '数字交通馆',
          desc: '',
          baseUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2022/数字交通馆/',
          iconUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/展馆logo/exhibition-area1.png',
          img: [['0G5A3735.jpg','0G5A3780.jpg','7U5A4519.jpg','7U5A4525.jpg','7U5A4572.jpg','7U5A4641.jpg','7U5A4686.jpg','7U5A4699.jpg','7U5A4733.jpg']]
        }
      ],
    }
  }
}
</script>

<style lang="less" scoped>
.first {
  background: url('../assets/views_bg.png') no-repeat scroll bottom / 100%;
  .first-info {
    .prv {
      margin: 20px 0;
      height: 240px;
      .fl {
        width: 423px;
        height: 238px;
        video {
          width: 100%;
          height: 100%;
          border-radius: 5px;
          box-shadow: 0 0 20px rgba(28, 36, 52, 0.5);
        }
      }
      .info {
        justify-content: flex-start;
        flex-wrap: wrap;
        padding-left: 50px;
        .item {
          text-align: center;
          height: 120px;
          width: 200px;
          padding: 20px 0;
          color: #125FAD;
          h1 {
            font-size: 28px;
          }
          p {
            color: #666;
            font-size: 18px;
            font-weight: 500;
          }
        }
      }
    }
  }
  .f-swiper {
    background: #F3F3F3;
    padding: 40px 0;
  }
  .forum-outer {
    background: url('../assets/maze_bg.svg') repeat;
    padding: 20px 0;
  }
  .forum-wrap {
    justify-content: space-around;
    flex-wrap: wrap;
    .forum-item {
      display: block;
      cursor: pointer;
      width: 310px;
      height: 250px;
      border-radius: 4px;
      margin-bottom: 30px;
    }
    .img {
      width: 310px;
      height: 170px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      overflow: hidden;
      img {
        max-height: 170px;
        width: auto;
        transition: all ease-out 0.2s;
      }
    }
    .forum-item:hover {
      img {
        transform: scale(1.05);
      }
    }
    .text {
      height: 60px;
      color: #333;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      border-top: 1px solid #F0F0F0;
      line-height: 60px;
      background: #F0F0F0;
    }
  }
  .elevator-wrap {
    .elevator {
      border-radius: 4px;
      width: 90px;
      height: 482px;
      left: 10px;
      border: 1px solid #D6D6D6;
      background: #FFF;
      div {
        margin: 0 auto;
      }
      .item {
        cursor: pointer;
        height: 80px;
        width: 90px;
        border-bottom: 1px solid #D6D6D6;
        color: #666666;
        font-size: 16px;
        font-weight: 400px;
        text-align: center;
      }
      .item:last-child {
        border-bottom: none;
      }
      .cur {
        background: #2585E5;
        color: #FFF;
      }
    }
  }
  .sponsor-list {
    padding-left: 110px;
  }
}
.annual-elevator {
  position: fixed;
  right: calc(50% - 640px);
  top: 20%;
  border: 1px solid #D6D6D6;
  background: #FFF;
  .annual {
    cursor: pointer;
    height: 50px;
    width: 80px;
    border-bottom: 1px solid #D6D6D6;
    color: #666666;
    font-size: 16px;
    line-height: 50px;
    font-weight: 400px;
    text-align: center;
  }
  .annual:last-child {
    border-bottom: none;
  }
  .cur {
    background: #2585E5;
    color: #FFF;
  }
}
</style>
